// eslint-disable-next-line no-restricted-imports
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { getJobsPublicApiEndpoint } from '~/config/jobs';

export const getParseCloudFunctionUrl = (country: string, functionName: string): string =>
  `${getJobsPublicApiEndpoint(country)}/functions/${functionName}`;

export const callParseCloudFunctionProxy = async <T = any>(
  country: string,
  functionName: string,
  body?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  const url = getParseCloudFunctionUrl(country, functionName);

  // used in the front, cannot use the axiosClient
  return axios.post<T>(url, body, config);
};
