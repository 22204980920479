// eslint-disable-next-line no-restricted-imports
import axios from 'axios';
import { useCallback, useState } from 'react';

import { getAddressApiUrl } from '~/config';
import { useCacheFromCallback, useDebouncedCallback, useLocale } from '~/hooks';
import { Prediction } from '~/utils/address';

type GetAutocompleteSuggestion = (input: string) => Promise<void>;

enum AWS_LOCATION_RESULT_TYPE {
  CITY = 'CITY',
  ADDRESS = 'ADDRESS',
  COUNTRY = 'COUNTRY',
}

export type UsePredictionProps = { debounceDelay?: number; type?: AWS_LOCATION_RESULT_TYPE };
export type UsePredictionResult = [Prediction[] | null, boolean, GetAutocompleteSuggestion];

export const usePredictions = ({
  type = AWS_LOCATION_RESULT_TYPE.ADDRESS,
  debounceDelay = 1000,
}: UsePredictionProps = {}): UsePredictionResult => {
  const { country } = useLocale();
  const [loading, setLoading] = useState(false);
  const [predictions, setPredictions] = useState<Prediction[]>(null);

  const findPredictions = useCacheFromCallback(async input => {
    try {
      const { data } = await axios.post<Prediction[] | null>(getAddressApiUrl(country, 'autocomplete'), {
        input,
        type,
      });
      if (data) return data;
    } catch (e) {
      console.error(e);
    }
    return [];
  });

  const [debouncePredictions, cancelDebouncePredictions] = useDebouncedCallback(async (input: string) => {
    setLoading(true);
    setPredictions(await findPredictions(input));
    setLoading(false);
  }, debounceDelay);

  const getPredictions = useCallback(async (input: string) => {
    if (input) {
      await debouncePredictions(input);
    } else {
      cancelDebouncePredictions();
      setPredictions([]);
    }
  }, []);

  return [predictions, loading, getPredictions];
};
